<template>
  <VuePreloader
    background-color="#191919"
    color="#fff"
    transition-type="fade-up"
    :loading-speed="5"
    :transition-speed="400"
  >
  </VuePreloader>

  <div class="m-auto back3 container-my d-flex flex-column">
    <div class="row d-flex flex-column flex-sm-row">
      <div class="col position-image-rel hover-image-scale">
        <img alt="Vue logo" class="img-fluid pointer" src="../assets/3.jpg" />
        <p class="position-image-abs textfont-image">
          Каверы и квартирники в <br />
          расцвете сил.
        </p>
      </div>

      <div class="col position-image-rel hover-image-scale">
        <img
          alt="Vue logo"
          class="img-fluid hover-image-scale pointer"
          src="../assets/1.jpg"
        />
        <p class="position-image-abs textfont-image">
          Шахматные турниры за чашкой чая.
        </p>
      </div>
    </div>

    <div class="row flex-column flex-sm-row mt-1">
      <div class="col-md-3 position-image-rel col-sm hover-image-scale">
        <img
          alt="Vue logo"
          class="img-fluid hover-image-scale pointer"
          src="../assets/10.jpg"
        />
        <p class="position-image-abs textfont-image px-2">
          Сегодня читает она.
        </p>
      </div>
      <div class="col-md position-image-rel hover-image-scale">
        <img
          alt="Vue logo"
          class="img-fluid hover-image-scale pointer"
          src="../assets/5.jpg"
        />
        <p class="position-image-abs mx-5 textfont-image fs-5">
          А они слушают.
        </p>
      </div>
      <div class="col-md-3 col-sm position-image-rel hover-image-scale">
        <img
          alt="Vue logo"
          class="img-fluid hover-image-scale pointer"
          src="../assets/8.jpg"
        />
        <p class="position-image-abs textfont-image">Еще партейку?</p>
      </div>
    </div>

    <div class="row mt-1">
      <div class="col-md-4 position-image-rel hover-image-scale">
        <img
          alt="Vue logo"
          class="img-fluid hover-image-scale pointer"
          src="../assets/12.jpg"
        />
        <p class="position-image-abs textfont-image">Что-то происходит.</p>
      </div>
      <div class="col-md position-image-rel hover-image-scale">
        <img
          alt="Vue logo"
          class="img-fluid hover-image-scale pointer"
          src="../assets/6.jpg"
        />
        <p class="position-image-abs textfont-image">В ритме танца.</p>
      </div>
      <div class="col-md-4 position-image-rel hover-image-scale">
        <img
          alt="Vue logo"
          class="img-fluid hover-image-scale pointer"
          src="../assets/4.jpg"
        />
        <p class="position-image-abs smx-4 textfont-image">Где ты?</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { VuePreloader } from "vue-preloader";
import { useCounterStore } from "../store.js";
const store = useCounterStore();
// store.ourArtSpaceApi();
</script>

<template>
  <div>
    <VuePreloader
      background-color="#191919"
      color="#fff"
      transition-type="fade-up"
      :loading-speed="5"
      :transition-speed="400"

    ></VuePreloader>

    <div class="container-my d-flex flex-wrap" v-if="store.showtemplate > 0">
      <div
        class="m-auto"
        v-for="(item, index) in store.advertisements"
        v-bind:key="index"
      >
        <div class="boxsize m-3 border p-2">
          <div class="text-light">
            <p class="fs-5">{{ item.text }}</p>
            <p class="fs-5">Имя: {{ item.name }}</p>
            <p class="fs-5">Телефон: {{ item.phone }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="store.showtemplate > 0">
      <button
        @:click="store.addtofetchfromapi()"
        type="submit"
        class="formbtn rounded border text-light fs-5 mt-1"
      >
        Посмотреть еще
      </button>
    </div>

    <div
      class="d-flex justify-content-center mt-4"
      v-if="store.showtemplate > 0"
    >
      <button type="submit" class="formbtn rounded border btn btn-lg">
        <RouterLink
          to="/Writetous"
          class="text-light link-underline link-underline-opacity-0"
        >
          Оставить заявку на проведение мероприятия
        </RouterLink>
      </button>
    </div>

    <div v-else>
      <div class="text-light text-center fs-5 m-5">
        В настоящий момент у нас нет объявлений, если вы хотите написать свое
        предложение перейдите по ссылке.
      </div>

      <div class="d-flex justify-content-center">
        <button type="submit" class="formbtn rounded border btn btn-lg">
          <RouterLink
            to="/Writetous"
            class="text-light link-underline link-underline-opacity-0"
          >
            Оставить заявку на проведение мероприятия
          </RouterLink>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { VuePreloader } from "vue-preloader";
import { useCounterStore } from "../store.js";
const store = useCounterStore();
store.advertisementsApi();
</script>

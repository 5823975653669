import { defineStore } from "pinia";

export const useCounterStore = defineStore("counter", {
  // наши переменные будут тут
  state: () => ({
    result: 4,
    showtemplate: null,
    posts: null,
    ourArtSpace: null,
    barEvents: null,
    advertisements: null,
    email: null,
    name: null,
    phone: null,
    text: null,
    checkbox: false,
    showMobileMenu: false,
  }),

  actions: {
    // async ourArtSpaceApi() {
    //   const response = await fetch(
    //     `https://api.kochegarnya.ru/api/v1/ourArtSpace`
    //   );
    //   const result = await response.json();
    //   this.ourArtSpace = result.data;
    // },

    async barEventsApi() {
      const response = await fetch(
        `https://api.kochegarnya.ru/api/v1/barEvents`
      );
      const result = await response.json();
      this.barEvents = result.data;
      // console.log(this.barEvents)
    },

    async advertisementsApi() {
      const response = await fetch(
        `https://api.kochegarnya.ru/api/v1/advertisements?_limit=${this.result}`
      );
      const result = await response.json();
      this.advertisements = result.data;
      this.showtemplate = result.data.length;
    },

    // выводит список объявлений
    async addtofetchfromapi() {
      this.result = this.result + 4;
      const response = await fetch(
        `https://api.kochegarnya.ru/api/v1/advertisements?_limit=${this.result}`
      );
      const result = await response.json();
      this.advertisements = result.data;
    },

    sendmessage() {
      // пишем регулярки для проверки  почты и телефона
      const regexPhone = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
      const regexEmail =
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      //  если наши регулярные вырашения  выполняются, тогда создаем объект откуда берем данные со сторы и инпутов и методом пост потом отправляем
      if (regexEmail.test(this.email) && regexPhone.test(this.phone) && this.checkbox==true  ) {
        let ContactForm = {
          text: this.text,
          name: this.name,
          phone: this.phone,
          email: this.email,
          accept_privacy: this.checkbox,
        } 
              ;
        fetch("https://api.kochegarnya.ru/api/v1/contactForm", {
          method: "POST",
          body: JSON.stringify(ContactForm),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          });
      }  else {

        alert('Пожалуйста  заполните корректно все поля и подтвердите согласие на обработку персональных данных.')
      }
    },
  },
});

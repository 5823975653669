<template>
  <div class="footer back2 text-danger mt-5">
    <hr class="whiteline" />
    <div class="container mt-5 text-center">
           <div class="row d-flex flex-column flex-md-row align-items-center">
        <div class="col">
          <a
            class="link-offset-1 link-light fs-2 m-3"
            href="https://wa.me/+79138259803"
            ><i class="bi bi-whatsapp"></i
          ></a>
          <a
            class="link-offset-1 link-light fs-2 m-3"
            href="https://t.me/+79138259803"
            ><i class="bi bi-telegram"></i
          ></a>
        </div>
        <div class="col fs-4 text-light">
          Совместно с вами мы организуем выставки, концерты, лекции,
          перформансы, джемы, игры и много всего еще.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style>
.back2 {
  background-image: url("../assets/Ellipse42.png"),
    url("../assets/Ellipse40.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 30%;
  min-height: 100px;
}
</style>

<template>
  <VuePreloader
    background-color="#191919"
    color="#fff"
    transition-type="fade-up"
    :loading-speed="5"
    :transition-speed="400"
  ></VuePreloader>

  <div class="container-my text-light">
    <div class="d-flex flex-column">
      <div class="col fs-4 mt-2 lh-sm text-start">
        <p class="text-center roboto-medium lh-sm m-4">
          Как организовать свое мероприятие в Арт-баре «Кочегарня»?
        </p>
        <p class="">
          1. Придумайте идею. Какое мероприятие вы хотите создать: выставку,
          концерт, кинопоказ, лекцию? А может дегустацию или мастер-класс?
        </p>
        <p class="">
          2. Определите проблематику. Какой вопрос ваше мероприятие вынесет в
          свет? Например, выставка-продажа хенд-мейд изделий затрагивает
          проблему массового потребления.
        </p>
        <p class="">
          3. Кому будет интересно ваше мероприятие? Например, на концерт песен
          Владимира Высоцкого придут совсем не те люди, что на концерт песен
          Нирваны.
        </p>
        <p class="">
          4. Цель и задачи. Цель - это то, ради чего вы создаете мероприятие.
          Задачи - это то, что необходимо сделать для достижения цели.
        </p>
        <p class="">
          5. Определитесь с примерными датами проведения мероприятий. Если вы
          решили провести поэтический вечер, то утвердить участников желательно
          за 5-7 дней. Афишу нужно разместить заранее, чтобы на ваше мероприятие
          пришло больше желающих.
        </p>
      </div>
      <div class="d-flex flex-column flex-lg-row col">
        <div class="col col-lg-6">
          <form class=" ">
            <div class="mb-3 mt-3">
              <label for="textevent" class="form-label fs-3">
                Опишите ваше планируемое мероприятие</label
              >
              <textarea
                v-model="store.text"
                class="form-control"
                name="textevent"
                id="textevent"
                rows="6"
                placeholder="Напишите 5-6 строк"
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="username" class="form-label fs-3"
                >Напишите ваше имя</label
              >
              <input
                v-model="store.name"
                type="text"
                class="form-control"
                name="username"
                id="username"
                placeholder="ваше имя"
              />
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label fs-3"
                >Напишите ваш телефон</label
              >
              <input
                v-model="store.phone"
                type="tel"
                class="form-control"
                name="phone"
                id="phone"
              />
            </div>
            <div class="mb-3">
              <label for="usermail" class="form-label fs-3"
                >Напишите email</label
              >
              <input
                v-model="store.email"
                type="email"
                class="form-control"
                name="usermail"
                id="usermail"
              />
            </div>
            <button
              type=""
              @:click="store.sendmessage()"
              class="mt-4 formbtn rounded border text-light btn btn-lg"
            >
              Отправить
            </button>
            <div class="mt-4 form-check mx-1">
              <input
                v-model="store.checkbox"
                type="checkbox"
                class="form-check-input focus-ring bg-dark fs-5 border border-2"
                id="Check"
                title="Пожалуйста, введите действующий электронный адрес."
                pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              />
              <label class="form-check-label fs-5" for="exampleCheck1">
                Согласен на обработку персональных данных
              </label>
            </div>
          </form>
        </div>
        <div class="col mx-5 hover-image-scale">
          <img
            alt="Vue logo"
            class="img-fluid hover-image-scale pointer border"
            src="../assets/2.jpg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { VuePreloader } from "vue-preloader";
import { useCounterStore } from "../store.js";
const store = useCounterStore();
</script>

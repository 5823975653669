<template>
  <div class="back">
    <div class="p-2 text-white bg-opacity-75 container-my">
      <div class="d-flex justify-content-center mt-5">
        <div class="text-center">
          <div>
            <img
              alt="Vue logo"
              class="rounded-circle img_size mt-3"
              src="../assets/kochegar.png"
            />
          </div>
          <div class="text-center fs-2 mt-2">АРТ-БАР</div>
          <div class="text-center rubik-mono-one-regular textfont">
            КОЧЕГАРНЯ
          </div>
          <div class="mt-2 m-auto w-75 fs-4 roboto-medium">
            "Это наше стремление вытащить наружу искусство, мало знакомое
            широкой публике. Мы даем возможность любым творческим личностям
            реализовать свои проекты, а зрителям стать их участниками."
          </div>
        </div>
      </div>

      <nav
        class="d-flex mt-5 nav nav-underline justify-content-center justify-content-xl-between"
      >
        <RouterLink
          to="/"
          class="link-light ааа  nav-link rubik-mono-one-regular fs-5 text-center"
        >
          Наше арт-пространство
        </RouterLink>
        <RouterLink
          to="/Poster"
          class="link-light ааа   nav-link rubik-mono-one-regular fs-5"
        >
          Афиша
        </RouterLink>
        <RouterLink
          to="/Advertisements"
          class="link-light ааа  nav-link rubik-mono-one-regular fs-5"
        >
          Объявления
        </RouterLink>
        <RouterLink
          to="/Writetous"
          class="link-light ааа   nav-link rubik-mono-one-regular fs-5"
        >
          Написать нам
        </RouterLink>
        <RouterLink
          to="/Contacts"
          class="link-light ааа nav-link rubik-mono-one-regular fs-5"
        >
          Как нас найти
        </RouterLink>
      </nav>

      <hr class="whiteline" />
    </div>
  </div>
</template>

<script setup>




</script>

<style>
.back {
  background-image: url("../assets/back.jpg");
  background-size: 100% 110%;
  height: 20%;
  @media (max-width: 770px) {
    background-image: none;
    background-image: 100% 100%;
  }
}
</style>

<template>
  <div class="container-my text-light">
    <VuePreloader
      background-color="#191919"
      color="#fff"
      transition-type="fade-up"
      :loading-speed="5"
      :transition-speed="400"
    ></VuePreloader>

    <Carousel
      :itemsToShow="4.7"
      :wrapAround="true"
      :transition="501"
      v-if="store.showMobileMenu"
      
    >
      <Slide v-for="slide in store.barEvents" :key="slide">
        <div class="text-light">
          <img
            :src="'https://api.kochegarnya.ru' + `${slide.image}`"
            class="img-fluid border pointer m-4"
          />
          <p class="text-start">
            {{ slide.name }} -
            {{ new Date(slide.date).toLocaleDateString("ru-RU") }}
          </p>
          <p class="text-start">
            начало в
            {{
              new Date(slide.date).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
            ч.
          </p>
        </div>
      </Slide>

      <template #addons>
        <Navigation class="text-light" />
      </template>
    </Carousel>

    <div v-else>
      <Carousel :wrap-around="true">
        <Slide v-for="slide in store.barEvents" :key="slide">
          <div class="text-light px-5">
            <img
              :src="'https://api.kochegarnya.ru' + `${slide.image}`"
              alt="failedtofetch"
              class="img-fluid border pointer mt-5"
            />
            <p class="text-start">
              {{ slide.name }} -
              {{ new Date(slide.date).toLocaleDateString("ru-RU") }}
            </p>
            <p class="text-start">
              начало в
              {{
                new Date(slide.date).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
              ч.
            </p>
          </div>
        </Slide>

        <template #addons>
          <Navigation class="text-light" />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script setup>
import { VuePreloader } from "vue-preloader";
import { useCounterStore } from "../store.js";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { onMounted } from "vue";

const store = useCounterStore();
store.barEventsApi();

onMounted(() => {
  const mediaQuery = window.matchMedia("(min-width:900px)");
  store.showMobileMenu = mediaQuery.matches;
  const listener = (e) => (store.showMobileMenu = e.matches);
  mediaQuery.addListener(listener);
});
</script>



<style scoped>
.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>

import Vue from 'vue'

import { createApp } from "vue";
import { createMemoryHistory, createRouter } from 'vue-router'
import { createPinia } from 'pinia'

// ИМПОРТ КОМПОНЕНТОВ
import App from "./App.vue";
import Advertisements from './components/Advertisements.vue'
import Contacts from './components/Contacts.vue'
import MainPage from './components/MainPage.vue'
import Poster from './components/Poster.vue'
import Writetous from './components/Writetous.vue'


const pinia = createPinia()

const routes = [
    { path: '/', component: MainPage },
    { path: '/Poster', component: Poster },
    { path: '/Advertisements', component: Advertisements },
    { path: '/Writetous', component: Writetous },
    { path: '/Contacts', component: Contacts },
]

const router = createRouter({
    history: createMemoryHistory(),
    routes,
})

const app = createApp(App)
app.use(pinia)
app.use(router)
app.mount('#app')